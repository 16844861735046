<template>
  <section id="this-is-art-wrapper">
    <heading-with-background-vue heading="This is art" />
    <p id="this-is-art-copy">
      <strong
        >It's a scene you've imagined yourself in hundreds of times, only
        better.</strong
      >
      The dance floor opens at your wedding. You join, and you're instantly
      surrounded by loved ones. The reception and ceremony went better than
      anyone could imagine. Dinner music was a
      <span class="highlight">✨vibe✨</span>, and the energy couldn't be
      higher. The opening song is a banger; one you know all-too-well.
      <em
        >Suddenly, the song starts sounding different than you've recognized
        before.</em
      >
      It <span class="highlight"><em>seamlessly</em> blends</span> into the
      next, <strong>taking the energy to a whole new level.</strong> You're no
      longer just dancing—you're experiencing a
      <span class="highlight"><strong>curated</strong></span>
      <span class="highlight"><strong>cross-era</strong></span>
      <span class="highlight"><strong>genre-blending</strong></span>
      musical journey–each track chosen and mixed to perfection, each transition
      a testament to the craft.
      <strong
        >It's not just music; it's the soundtrack to one of the most important
        days of your life, tailored to you, in real-time.</strong
      >
      <span class="art-section"
        ><strong
          >This is more than a playlist. This is art. And you're at the center
          of it.</strong
        ></span
      >
    </p>
  </section>
</template>

<script>
import HeadingWithBackgroundVue from "../SharedComponentsUI/HeadingWithBackground.vue";

export default {
  data() {
    return {
      blockquote: "",
    };
  },
  components: { HeadingWithBackgroundVue },
};
</script>

<style>
#this-is-art-wrapper {
  width: 100%;
  height: 50vh;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#this-is-art-copy {
  width: 85%;
  max-width: 800px;
  margin: 60px auto;
  line-height: 1.5rem;
}

#this-is-art-copy span {
  color: white;
  background-color: black;
  padding: 3px 5px;
  font-weight: bold;
  font-style: italic;
  margin: auto 2px;
}
</style>
