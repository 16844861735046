<template>
  <div class="playlist-wrapper">
    <h4>{{ playlist.title }}</h4>
    <p>{{ playlist.description }}</p>
    <div v-if="activeTab === 'spotify'" v-html="playlist.spotifyLink"></div>
    <div
      v-if="activeTab === 'apple-music'"
      v-html="playlist.appleMusicLink"
    ></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
  components: {},
  props: ["playlist", "activeTab"],
};
</script>
<style scoped>
.playlist-wrapper {
  scroll-snap-align: center;
  min-width: 300px;
  margin: 0 30px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: black;
  padding: 20px;
  border-radius: 5px;
}

p,
h4 {
  color: white;
  margin: 5px;
}

p {
  font-size: 10pt;
}

h4 {
  font-size: 25pt;
}
</style>
