<template>
  <div id="hero-gallery-wrapper">
    <div class="hero-gallery-grid">
      <div
        v-for="image in loadedImages"
        :key="image.key"
        class="hero-gallery-image-container"
      >
        <img :src="image.url" alt="" class="hero-gallery-picture" />
      </div>
    </div>
    <div class="bottom-fade-bar"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      images: [],
      loadedImages: [],
      loading: false,
      allLoaded: false,
    };
  },
  methods: {
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    async loadImages() {
      if (this.loading || this.allLoaded) return;

      this.loading = true;
      try {
        let { data } = await axios.get(
          `https://yjt6qp6bk0.execute-api.us-east-1.amazonaws.com/gallery-photos`
        );
        const newImages = this.shuffleArray(data);
        this.images = [...this.images, ...newImages];
        this.preloadImages(newImages);
      } catch (error) {
        console.error("Error loading images:", error);
      } finally {
        this.loading = false;
      }
    },
    preloadImages(images) {
      images.forEach((image) => {
        const img = new Image();
        img.onload = () => {
          this.loadedImages.push(image);
        };
        img.src = image.url;
      });
    },
  },
  mounted() {
    this.loadImages();
  },
};
</script>

<style>
#hero-gallery-wrapper {
  z-index: 0;
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

.hero-gallery-grid {
  column-count: 4;
  column-gap: 10px;
  padding: 10px;
}

.hero-gallery-image-container {
  break-inside: avoid;
  margin-bottom: 10px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.hero-gallery-picture {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bottom-fade-bar {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 2;
  pointer-events: none;
}

@media (max-width: 1200px) {
  .hero-gallery-grid {
    column-count: 3;
  }
}

@media (max-width: 900px) {
  .hero-gallery-grid {
    column-count: 2;
  }
}

@media (max-width: 600px) {
  .hero-gallery-grid {
    column-count: 2;
    column-gap: 5px;
    padding: 5px;
  }

  .hero-gallery-image-container {
    margin-bottom: 5px;
  }
}
</style>
