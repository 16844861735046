<template>
  <section>
    <HeadingWithBackground heading="Oh! I almost forgot!" />
    <p>
      I have a few services that I offer in addition to my DJ services. I price
      these lower than market rates as a perk for my clients!
    </p>
    <div id="add-ons-container">
      <div class="add-on" v-for="(addOn, index) in addOns" :key="index">
        <div class="add-on-gallery-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.503"
            height="23.619"
            viewBox="0 0 13.503 23.619"
            fill="white"
            v-if="photoLength(addOn) > 1"
            @click="changeAddOnPhoto(addOn, 'back')"
          >
            <path
              id="Icon_ionic-ios-arrow-back"
              data-name="Icon ionic-ios-arrow-back"
              d="M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z"
              transform="translate(-11.251 -6.194)"
            />
          </svg>

          <img class="photo" :src="addOn.photos[addOn.index]" alt="" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.503"
            height="23.619"
            viewBox="0 0 13.503 23.619"
            fill="white"
            v-if="photoLength(addOn) > 1"
            @click="changeAddOnPhoto(addOn, 'forward')"
          >
            <path
              id="Icon_ionic-ios-arrow-back"
              data-name="Icon ionic-ios-arrow-back"
              d="M20.683,18,11.746,9.07a1.688,1.688,0,1,1,2.391-2.384L24.262,16.8a1.685,1.685,0,0,1,.049,2.327L14.144,29.32a1.688,1.688,0,0,1-2.391-2.384Z"
              transform="translate(-11.251 -6.194)"
            />
          </svg>
        </div>
        <h3 class="add-on-heading">{{ addOn.name }}</h3>
        <h4 class="add-on-price">{{ addOn.price }}</h4>
        <p class="add-on-description">{{ addOn.description }}</p>
      </div>
    </div>
  </section>
</template>
<script>
import HeadingWithBackground from "../SharedComponentsUI/HeadingWithBackground.vue";

export default {
  data() {
    return {
      leftArrow: require("../../assets/left-arrow.svg"),
      rightArrow: require("../../assets/right-arrow.svg"),
      addOns: [
        {
          name: "Digital Photo Booth",
          price: "$350",
          description:
            "The digital photo booth is seriously the coolest. It is a self-serve kiosk that guides your guests through creating GIFs, boomerangs, photos, and videos, sending that content to your guests' phones, and also keeps a copy of it for your gallery!",
          photos: [
            "https://colewebsitefiles.s3.amazonaws.com/PhotoboothKiosk.webp",
          ],
          index: 0,
        },
        {
          name: "Uplighting",
          price: "$25/ea",
          description:
            "Uplighting can do wonders in a room, and could really be one of the most affordable ways to create a dramatic effect. Uplighting consists of small lighting devices that can shoot a beam of any color up a wall!",
          photos: [
            "https://colewebsitefiles.s3.amazonaws.com/JewelboxUplighting.jpeg",
            "https://colewebsitefiles.s3.amazonaws.com/BarnettUplighting.jpeg",
          ],
          index: 0,
        },
      ],
    };
  },
  computed: {},
  methods: {
    photoLength(addOn) {
      console.log(addOn.photos.length);
      return addOn.photos.length;
    },
    changeAddOnPhoto(addOn, direction) {
      if (direction === "back") {
        if (addOn.index === 0) {
          addOn.index = addOn.photos.length - 1;
        } else {
          addOn.index--;
        }
      } else {
        if (addOn.index === addOn.photos.length - 1) {
          addOn.index = 0;
        } else {
          addOn.index++;
        }
      }
    },
  },
  created() {},
  components: {
    HeadingWithBackground,
  },
  props: {},
  emits: {},
};
</script>
<style scoped>
#add-ons-heading {
  height: 60px;
}
section {
  padding: 10vw;
  /* height: 100vh; */
}

#add-ons-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.add-on {
  max-width: 400px;
  background-color: #000000;
  border-radius: 2px;
  padding: 20px;
  margin: 10px;
  height: fit-content;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-on-gallery-wrapper {
  width: 100%;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.add-on-heading,
.add-on-description,
.add-on-price {
  color: #ffffff;
  text-align: right;
}

.arrow {
  color: white;
}

.photo {
  width: 300px; /* Set a fixed width */
  height: 300px; /* Set a fixed height */
  object-fit: cover;
  object-position: center;
  margin: 0;
}
</style>
