<template>
  <div class="promotion-box">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      class="close-button"
      xmlns="http://www.w3.org/2000/svg"
      @click="closeModal"
    >
      <path
        d="M7 6L13 14M13 6L7 14M10 19C12.3869 19 14.6761 18.0518 16.364 16.364C18.0518 14.6761 19 12.3869 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10C1 12.3869 1.94821 14.6761 3.63604 16.364C5.32387 18.0518 7.61305 19 10 19Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <h2 class="promotional-text">Location Promotions:</h2>
    <p class="promotion" v-for="(promotion, index) in promotions" :key="index">
      {{ promotion }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["promotions"],
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style>
@media screen and (max-width: 768px) {
  .promotion-box {
    width: 80%;
  }
}
.promotional-text {
  font-family: bebas-neue;
  padding: 10px;
  color: white;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.promotion-box {
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  padding: 20px;
  margin: 20px auto;

  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 2px;
  background-color: black;
  color: white;
  text-align: center;
}

.promotion {
  color: black;
  background-color: white;
  padding: 10px;
  margin: 5px;
  border-radius: 2px;
}
</style>
