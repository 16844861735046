<template>
  <section>
    <hero-gallery-vue />
    <div id="hero-body">
      <h1>Cole Coleman</h1>
    </div>
    <div class="scroll-text">
      <p>Scroll for more</p>
      <img src="../../assets/down-arrow.svg" alt="" />
    </div>
  </section>
</template>
<script>
import HeroGalleryVue from "../SharedComponentsUI/HeroGallery.vue";

export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
  components: {
    HeroGalleryVue,
  },
  props: [],
};
</script>
<style scoped>
@media screen {
  section {
    height: 100vh;
    width: 100%;
    position: relative;
    z-index: 1;
  }
  h1,
  h3,
  p,
  a {
    z-index: 1;
    font-family: bebas-neue, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: black;
  }

  .scroll-text {
    z-index: 2;
    position: relative;
  }

  #header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }

  #tagline-name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #tagline-name-container > img {
    height: 25px;
    padding: 0 10px;
    border-right: 2px solid black;
  }

  #tagline-name-container > h3 {
    font-size: 14pt;
    padding: 0 10px;
  }

  .sign-in {
    margin: 10px;
    font-size: 14pt;
  }

  #hero-body {
    height: calc(100% - 200px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #hero-body > h1 {
    font-size: 40pt;
    color: white;
    background-color: black;
    padding: 5px 30px;
  }

  @media (min-width: 800px) {
    #header {
      height: 100px;
    }

    #tagline-name-container > img {
      height: 50px;
      padding: 0 20px;
    }

    #tagline-name-container > h3 {
      font-size: 20pt;
      padding: 0 20px;
    }

    .sign-in {
      margin: 40px;
      font-size: 15pt;
    }

    #hero-body > h1 {
      font-size: 50pt;
      padding: 5px 100px;
    }
  }
}
</style>
