<template>
  <section>
    <div id="shoutout-wrapper">
      <shoutout-with-number-detail :shoutout="shoutouts[0]" />
      <shoutout-with-number-detail :shoutout="shoutouts[1]" />
      <shoutout-with-number-detail :shoutout="shoutouts[2]" />
    </div>
    <div id="who-is-cole">
      <copy-section-with-heading :heading="heading" :body="body" />
    </div>
    <!-- <div id="block-quote">
      <block-quote :quote="quote" />
    </div> -->
  </section>
</template>
<script>
// import BlockQuote from "../SharedComponentsUI/BlockQuote.vue";
import CopySectionWithHeading from "../SharedComponentsUI/CopySectionWithHeading.vue";
import ShoutoutWithNumberDetail from "../SharedComponentsUI/ShoutoutWithNumberDetail.vue";
export default {
  data() {
    return {
      heading: "Who is Cole Coleman?",
      body: "More than just a DJ — Cole is a husband to Lauryn, and an entrepreneur with multiple experiences, projects, and passions. From real estate agency and real estate investment to owning, growing, and operating a mid-sized, multi-operation DJ company (House DJ), and then finally (and most recently) to founding business management software startup Simple City Software. Additional to Cole and Lauryn’s own success in the aforementioned ventures, Cole was able to gain years of additional event-industry experience through working alongside Lauryn and her family at their family of wedding venues and event service companies. He draws on all of these experiences to bring something additional to his events, and uses the past experience in events through family business and large-volume event business of his own to navigate your event with ease and expertise.",
      quote:
        "I really love weddings, and all of the experiences I’ve had with them. Every wedding I’ve been able to be a part of is the start of a new journey. They’re so full of love, joy, and excitement, and to be able to be an integral part of that is a very humbling experience. ",
      shoutouts: [
        {
          title: "Events",
          number: "750+",
        },
        {
          title: "Years",
          number: "10+",
        },
        {
          title: "Venues",
          number: "85+",
        },
      ],
    };
  },
  methods: {},
  created() {},
  components: { CopySectionWithHeading, ShoutoutWithNumberDetail },
  props: [],
};
</script>
<style scoped>
@media screen {
  section {
    height: 80vh;
    width: 80%;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    align-content: space-evenly;
    justify-content: space-evenly;
  }

  #who-is-cole {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #block-quote {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #shoutout-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
</style>
