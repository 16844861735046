<template>
  <section id="footer">
    <div class="footer-half left">
      <p>Copyright, {{ copyrightYear }}, DJ Cole Coleman</p>
    </div>
    <div class="footer-half right">
      <p>cole@thecolecoleman.com</p>
      <p>636-212-4414</p>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    copyrightYear() {
      return new Date().getFullYear();
    },
  },
  methods: {},
  created() {},
  components: {},
  props: [],
};
</script>
<style scoped>
section {
  background-color: black;
  color: white;
  height: 80px;
  width: 100vw;
  scroll-snap-align: end;
  display: flex;
  justify-content: space-evenly;
}
.footer-half {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

p {
  margin: 5px;
  font-family: bebas-neue, sans-serif;
}
</style>
