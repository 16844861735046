<template>
  <section>
    <!-- <div class="heading"> -->
    <heading-with-background
      heading="Some of my favorite tracks"
      alignment="left"
    />
    <!-- <img
        src="../../assets/spotify.svg"
        :class="activeTab === 'spotify' ? 'active' : ''"
        @click="changeActiveTab('spotify')"
        alt=""
      /> -->
    <!-- <img
        src="../../assets/apple-music.svg"
        :class="activeTab === 'apple-music' ? 'active' : ''"
        @click="changeActiveTab('apple-music')"
        alt=""
      /> -->
    <!-- </div> -->
    <div class="playlist-scroller">
      <playlist-card
        v-for="(playlist, index) in playlists"
        :key="index"
        :playlist="playlist"
        :activeTab="activeTab"
      />
    </div>
  </section>
</template>
<script>
import PlaylistCard from "../SharedComponentsUI/PlaylistCard.vue";
import HeadingWithBackground from "../SharedComponentsUI/HeadingWithBackground.vue";
export default {
  data() {
    return {
      activeTab: "spotify",
      playlists: [
        {
          title: "Pre-Ceremony",
          description:
            "This is generally the list I’ll pull from before the ceremony",
          spotifyLink: `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/1sccH9kEyoYkfJ600T5oV7?utm_source=generator" width="100%" height="450px" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>`,
          // appleMusicLink: `<iframe allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="450" style="width:100%;max-width:660px;overflow:hidden;background:black;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/playlist/a-list-pop/pl.5ee8333dbe944d9f9151e97d92d1ead9"></iframe>`,
        },
        {
          title: "Dinner Vibes",
          description:
            "This is a taste of my favorite tracks that I might pull from during dinner.",
          spotifyLink: `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/0J5cH2AYwX8LStTkKtG7MA?utm_source=generator" width="100%" height="450" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>`,
          // appleMusicLink: `<iframe allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="450" style="width:100%;max-width:660px;overflow:hidden;background-color:black;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/playlist/a-list-pop/pl.5ee8333dbe944d9f9151e97d92d1ead9"></iframe>`,
        },
        {
          title: "Deep Cuts",
          description:
            "These are some of my favorite long-forgotten tracks for dance and dinner.",
          spotifyLink: `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/7h6vl1ehN1rrcPAxx7J9sx?utm_source=generator" width="100%" height="450" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>`,
          // appleMusicLink: `<iframe allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="450" style="width:100%;max-width:660px;overflow:hidden;background:black;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/playlist/a-list-pop/pl.5ee8333dbe944d9f9151e97d92d1ead9"></iframe>`,
        },
        {
          title: "Dance",
          description:
            "This playlist has a taste of my favorite tracks to get people up and moving!",
          spotifyLink: `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/0ro0LJxwvcU2rqEAHoHFnN?utm_source=generator" width="100%" height="450" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>`,
          // appleMusicLink: `<iframe allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="450" style="width:100%;max-width:660px;overflow:hidden;background:black;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/playlist/a-list-pop/pl.5ee8333dbe944d9f9151e97d92d1ead9"></iframe>`,
        },
        {
          title: "Music Discovery",
          description:
            "A few you probably haven't heard. I usually mix these into dinner or cocktail hour.",
          spotifyLink: `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/0fJ529aNMKF8PinjpJ7X54?utm_source=generator" width="100%" height="450" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>`,
          // appleMusicLink: `<iframe allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="450" style="width:100%;max-width:660px;overflow:hidden;background:black;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/playlist/a-list-pop/pl.5ee8333dbe944d9f9151e97d92d1ead9"></iframe>`,
        },
      ],
    };
  },
  methods: {
    changeActiveTab(tab) {
      this.activeTab = tab;
    },
  },
  created() {},
  components: {
    PlaylistCard,
    HeadingWithBackground,
  },
  props: [],
};
</script>
<style scoped>
@media screen {
  section {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  img {
    height: 20px;
    margin: 10px;
    padding-bottom: 15px;
  }

  /* .active {
    padding-bottom: 10px;
    border-bottom: 5px solid black;
  } */

  .playlist-scroller {
    display: flex;
    width: 100%;
    /* height: 100%; */
    padding: 10px 0;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-padding: 0px;
  }

  @media (min-width: 800px) {
    img {
      height: 30px;
      margin: 15px;
      padding-bottom: 20px;
    }

    /* .active {
      border-bottom: 10px solid black;
    } */
  }
}
</style>
