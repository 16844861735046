<template>
  <div id="myShop">
    <a href="https://dj-cole-coleman.myspreadshop.com"
      >Visit my Spreadshop: dj-cole-coleman</a
    >
  </div>
</template>

<script>
export default {
  name: "Merch",

  async mounted() {
    await this.initializeSpreadShop();
    this.editSpreadshop();
  },

  methods: {
    initializeSpreadShop() {
      return new Promise((resolve, reject) => {
        // Spreadshop configuration
        const configScript = document.createElement("script");
        configScript.type = "text/javascript";
        configScript.async = true;
        configScript.innerHTML = `
          var spread_shop_config = {
            shopName: 'dj-cole-coleman',
            locale: 'us_US',
            prefix: 'https://dj-cole-coleman.myspreadshop.com',
            baseId: 'myShop'
          };
        `;

        // External Spreadshop script
        const externalScript = document.createElement("script");
        externalScript.type = "text/javascript";
        externalScript.async = true;
        externalScript.src =
          "https://dj-cole-coleman.myspreadshop.com/shopfiles/shopclient/shopclient.nocache.js";

        // Append the configuration script to the document's head
        document.head.appendChild(configScript);

        // Listen for the external script to load before appending it
        externalScript.onload = () => {
          console.log("Spreadshop script loaded successfully");
          resolve(); // Resolve the promise here
        };

        // Handle script load error
        externalScript.onerror = (error) => {
          console.error("Error loading the Spreadshop script:", error);
          reject(error); // Reject the promise here
        };

        // Append the external script to the document's head
        document.head.appendChild(externalScript);
      });
    },
    editSpreadshop() {
      try {
        setTimeout(() => {
          let headerImage = document.querySelector(".sprd-header__image");
          headerImage.remove();
          //   let sprdFooter = document.querySelector("#sprd-footer");
          //   sprdFooter.remove();
          let sprdDepartmentFilterCategories = document.querySelector(
            "#sprd-department-filter"
          );
          let cartIcon = document.querySelector("#sprd-basket");
          sprdDepartmentFilterCategories.appendChild(cartIcon);
        }, 120);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
/* Component-specific styles go here */
</style>
