<template>
  <div id="header">
    <div id="tagline-name-container" @click="routeHome()">
      <img src="../../assets/logo.svg" alt="" />
      <h3>Luxury Wedding DJ</h3>
    </div>
    <div id="link-text-container">
      <router-link to="Merch" class="link-text">Merch</router-link>
      <p class="link-text">
        <a href="https://app.spindj.io">Sign In</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    routeHome() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
@media screen {
  section {
    height: 100vh;
    width: 100%;
  }
  h1,
  h3,
  p,
  a {
    font-family: bebas-neue, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: black;
  }

  #header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }

  #tagline-name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #tagline-name-container > img {
    height: 25px;
    padding: 0 10px;
    border-right: 2px solid black;
  }

  #tagline-name-container > h3 {
    font-size: 14pt;
    padding: 0 10px;
  }
  #link-text-container {
    display: flex;
  }
  .link-text {
    margin: 10px;
    font-size: 14pt;
  }

  @media (min-width: 800px) {
    #header {
      height: 100px;
    }

    #tagline-name-container > img {
      height: 50px;
      padding: 0 20px;
    }

    #tagline-name-container > h3 {
      font-size: 20pt;
      padding: 0 20px;
    }

    .link-text {
      margin: 40px;
      font-size: 15pt;
    }
  }
}
</style>
