<template>
  <section id="copy-gallery-wrapper">
    <img
      src="../../assets/left-arrow.svg"
      @click="changeCounter('backwards')"
      alt=""
    />
    <div class="copy-gallery-item">
      <block-quote :quote="gallery[counter].quote" />
      <copy-section-with-heading
        :heading="gallery[counter].heading"
        :body="gallery[counter].body"
      />
    </div>
    <img
      src="../../assets/right-arrow.svg"
      @click="changeCounter('forward')"
      alt=""
    />
  </section>
</template>
<script>
import BlockQuote from "../SharedComponentsUI/BlockQuote.vue";
import CopySectionWithHeading from "../SharedComponentsUI/CopySectionWithHeading.vue";
export default {
  data() {
    return {
      counter: 0,
      gallery: [
        {
          quote:
            "Each and every event I perform is the sum of the events I’ve performed in the past, just as that event will heighten the experience of the next. ",
          heading: "The Difference",
          body: "Though “DJ” is his title, Cole does much more than play music. Early on in his career as a DJ, he decided to learn as much as possible, dive in, and completely immerse himself in the craft. This manifested in different forms, such as in practicing hours per day in song-blending and mixing, studying the psychology behind events, dance floors, and weddings, and even to shadowing and working for other wedding vendors in the industry to better learn their needs. Even going as far as reading technical manuals on sound dynamics, sound equipment, and learning the technical ins-and-outs of equipment; not just how to use them, but how they work. Lastly, and most importantly, studying public speaking, and the art of captivating a crowd. Early in his career, Cole would charge unbelievably small rates, just to get in front of a crowd to practice music, technicals, and speaking; all things that would help him become the professional he is today. ",
        },
        {
          quote:
            "This isn’t about any one big moment. It’s thousands of little moments coming together into the collective. It’s all of us there, in that place, sharing in this experience together. ",
          heading: "The Experience",
          body: "Though you’re likely to only remember the big moments at your wedding, Cole believes that building a proper vibe and energy is all about the small moments, and the moments each individual guest has within themselves. It’s the guy at Table 9 hearing a song that he hasn’t heard since high school during dinner; it brings him back to simpler times. It’s the plethora of guests who will “Shazam” a song they’ve never heard to save it; it just became their new favorite song. It’s your college friends all together belting your anthem. It’s small conversations, it’s memories, it’s the right song at the right time to strike that emotional response, it’s picking up on nuances and listening carefully for inside jokes in the Maid of Honor toast. For Cole, it’s so much more than pressing play. For him, it’s about being present, in the moment, and simply allowing himself to be a part of this experience, then choosing the perfect soundtrack for that exact moment. ",
        },
        {
          quote:
            "Some of my most memorable moments at these events are short conversations, interactions, or reactions from guests. I love creating an atmosphere that is comfortable enough to nurture that.",
          heading: "The Atmosphere",
          body: "As a host, it’s necessary that Cole not only makes announcements, but rather captivates your guests. Cole prides himself in his ability to go further than simply talking to your guests, but to relay information in a colorful and exciting way and to engage your guests in what is happening at that moment. Cole’s hosting abilities allow him to come across as comfortable and present, not simply reciting words. By including details from the day, reacting to your guests, taking in the atmosphere, and being emotionally present, Cole is able to truly capture what your guests are saying, thinking, and feeling, and communicate it in a format that everyone can share in and experience. This enhances the experience for all, and raises the collective energy by letting guests feel comfortable, heard, and involved.",
        },
      ],
    };
  },
  methods: {
    changeCounter(direction) {
      if (direction === "forward") {
        if (this.counter === 2) {
          this.counter = 0;
        } else {
          this.counter++;
        }
      } else {
        if (this.counter === 0) {
          this.counter = 2;
        } else {
          this.counter--;
        }
      }
    },
  },
  components: {
    BlockQuote,
    CopySectionWithHeading,
  },
};
</script>
<style scoped>
@media screen {
  #copy-gallery-wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .copy-gallery-item {
    width: 75%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  img {
    cursor: pointer;
    width: 10px;
  }

  img:hover {
    filter: drop-shadow(1px 1px 1px black);
  }
  @media (min-width: 800px) {
    .copy-gallery-item {
      width: 80%;
      height: 60%;
    }
  }
}
</style>
