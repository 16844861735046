<template>
  <div>
    <Hero />
    <this-is-art />
    <who-is-cole />
    <copy-gallery />
    <topics />
    <favorite-tracks />
    <!-- <instagram-gallery /> -->
    <locations-pricing />
    <add-ons />
    <contact-form />
    <Footer />
  </div>
</template>
<script>
import Hero from "../components/views/Hero.vue";
import ThisIsArt from "../components/views/ThisIsArt.vue";
import CopyGallery from "../components/views/CopyGallery.vue";
import WhoIsCole from "../components/views/WhoIsCole.vue";
import Topics from "../components/views/Topics.vue";
import FavoriteTracks from "../components/views/FavoriteTracks.vue";
import LocationsPricing from "../components/views/LocationsPricing.vue";
// import InstagramGallery from "../components/views/InstagramGallery.vue";
import ContactForm from "../components/views/ContactForm.vue";
import AddOns from "../components/views/AddOns.vue";
import Footer from "../components/views/Footer.vue";

export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
  components: {
    Hero,
    ThisIsArt,
    CopyGallery,
    WhoIsCole,
    Topics,
    FavoriteTracks,
    AddOns,
    // InstagramGallery,
    ContactForm,
    LocationsPricing,
    Footer,
  },
};
</script>
<style scoped></style>
