<template>
  <section v-if="successfullySent">
    <h2>Received! Cole will be with you shortly.</h2>
  </section>
  <section v-else>
    <h2>Questions, comments, booking?</h2>
    <iframe
      id="scs-contact-form"
      frameborder="0"
      style="
        max-width: 650px;
        height: 482px;
        width: 100%;
        @media (min-width: 500px) {
          height: 402px;
        }
      "
      src="https://api.spindj.io/widgets/7e9b6fe0-0b16-4076-8171-a399992d876b/forms/widget_form1684514643399"
    ></iframe>
  </section>
</template>
<script></script>
<style scoped>
@media screen {
  section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h2 {
    font-size: 30pt;
    margin: 0px;
  }

  .error-message {
    color: red;
    font-style: italic;
  }

  .submit-button {
    width: 200px;
    background-color: black;
    cursor: pointer;
  }

  .submit-button h3 {
    color: white;
  }
  @media (min-width: 800px) {
    h2 {
      font-size: 40pt;
    }
  }
}
</style>
