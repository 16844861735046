<template>
  <svg
    id="Group_42"
    data-name="Group 42"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="1666.856"
    height="1028.822"
    viewBox="0 0 1666.856 1028.822"
  >
    <path
      id="Path_39"
      data-name="Path 39"
      d="M535.7,487.146l94.213,4.02c-1.15,23.683-2.451,46.2-3.256,68.724-.449,12.561,4.8,17.035,16.8,19.859,9.865,2.321,19,7.58,28.712,10.764,8.275,2.713,18.7.417,23.362,10.837,11.81-7.044,22.025,13.422,34.14,2a2.487,2.487,0,0,1,2.254-.244c18.422,9.317,36.383-3.955,54.423-1.045,7.38,1.189,14.092,6.268,21.231,9.289,4.23,1.79,8.7,3.017,14.661,5.043.591,10.58,2.023,22.411,1.73,34.2-.43,17.336,4.993,32.712,13.282,47.406,5.245,9.3,4.913,18.287,1.83,28.549-3.33,11.087-3.6,23.065-5.723,34.559-.446,2.425-2.669,5.151-4.84,6.526-11.278,7.144-22.87,13.787-34.227,20.808C772.8,801.729,751.3,815.007,730.01,828.6c-6.447,4.115-12.239,9.254-17.756,13.484-3.341,24.748-11.094,49.883,2.578,74.32-2.945,1.206-4.622,2.594-5.69,2.225-16.985-5.881-34.149-11.385-50.662-18.408-4.958-2.11-9.284-8.016-11.741-13.2-5.119-10.806-11.29-21.612-9.2-34.555.208-1.287-2.1-3.261-3.583-4.5-17.128-14.251-26.518-33.246-34.795-53.449-8.086-19.735-19.435-37.2-44.4-38.034a28.719,28.719,0,0,1-7.054-1.047c-14.454-4.234-22.794-.341-28.857,13.634-7.6,17.53-14.628,19.216-28.907,7.11a89.129,89.129,0,0,0-8.719-6.072c-11.959-7.9-20.812-16.729-21.553-33.071-.573-12.636-5.207-25.179-18.923-31.97-5.706-2.824-9.932-8.991-14.289-14.1-8.547-10.023-16.715-20.37-27.111-33.125l122.326,9.425c4.676-60.085,9.281-119.255,14.018-180.119"
      transform="translate(90.286 110.134)"
    />
    <path
      id="Path_40"
      data-name="Path 40"
      d="M24.632,210.661l114.192,32.483c-7.919,30.782-14.458,61.028-23.711,90.42-5.882,18.682-3.665,33.043,7.723,49.318,36,51.448,70.684,103.837,104.951,156.464,9.33,14.329,15.4,30.777,22.967,46.253l-5.194-.128c-4.8,9.356-8.06,20.049-14.775,27.73-8.343,9.544-8.993,17.865-2.132,29.718-29.355,2.233-56.929-5.012-86.138-8.391,2.575-19.161-2.42-36.109-16.67-47.691-9.616-7.816-12.641-23.33-28.125-24.3-1.579-.1-3.861-2.061-4.4-3.641-5.943-17.4-21.681-20.506-36.353-24.626-7.131-2-8.271-5.98-5.647-11.394,6.3-13.009.136-23.306-5.614-34.408A337.906,337.906,0,0,1,28.791,449.37c-1.053-2.871,1.931-7.694,3.93-11.106,3.667-6.264,6.7-11.3-1.893-16.761-10.444-6.634-8.457-17.421-7.588-27.934.427-5.152-1.553-10.716-3.307-15.8-5.679-16.467-12.062-32.7-17.426-49.266-1.078-3.326.4-8.041,1.954-11.559,6.385-14.43,3.822-27.475-3.621-40.865C-1.029,272.721.418,265.018,3.232,262c11.958-12.836,18.569-27.36,18.747-44.906.018-1.8,1.43-3.575,2.653-6.431"
      transform="translate(0 47.626)"
    />
    <path
      id="Path_41"
      data-name="Path 41"
      d="M309.494,227.19c-6.81-18.743-13.075-35.987-19.858-54.653l-12.676,6.339c-.284-4.827-1.312-8.737-.532-12.249a57.175,57.175,0,0,1,5.5-14.441c5.3-9.879,6.377-17.207-3.913-26.2-8.714-7.621-16.748-19.157-19.09-30.227-4.2-19.833-7.821-40.859,2.149-58.109l313.6,47.18c-4.437,48.186-9.117,98.993-14.007,152.084L363.507,212.233c-.872,4.16-1.669,7.957-2.64,12.591l-51.373,2.366"
      transform="translate(57.577 8.511)"
    />
    <path
      id="Path_42"
      data-name="Path 42"
      d="M532.849,668.932,412.841,659.1c-.77,3.255-1.526,6.455-2.515,10.629L359.636,662.7c-1.265,5.826-2.341,10.776-3.787,17.44l-23.814-2.659c10.046-75.031,19.931-148.846,29.995-224.012,21.583,2.491,42.3,5.063,63.068,7.241,37.568,3.942,75.129,8.052,112.77,11.168,9.132.755,11.54,3.612,10.714,12.166q-5.31,54.927-9.956,109.918c-2.063,24.083-3.729,48.2-5.776,74.965"
      transform="translate(75.066 102.521)"
    />
    <path
      id="Path_43"
      data-name="Path 43"
      d="M238.9,431.912l152.555,25.994c-10,74.085-19.9,147.488-30.073,222.857-28.177-4.716-55.534-1.832-79.931-16.535-31.64-19.069-63.6-37.605-95.405-56.411-1.967-1.164-3.728-2.675-7.327-5.291,4.1-2.223,7.184-3.413,9.684-5.353,4.62-3.582,10.483-6.774,2.575-13.609-2.17-1.876-2.686-10.053-1-11.282,11.121-8.11,6.6-27.091,22.426-31.682.921-.267,1.887-4.9.976-6.274-11.9-17.948-5.373-37.281-4.56-56.151.217-5.013,1.056-10,1.7-15.794,3.2.907,5.619,1.148,7.58,2.219,11.68,6.385,13.819,5.456,16.427-7.941,1.577-8.1,2.883-16.248,4.376-24.747"
      transform="translate(40.404 97.647)"
    />
    <path
      id="Path_44"
      data-name="Path 44"
      d="M247.046,507.566c-16.686-10.038-20.943-8.095-23.064,9.024-1.063,8.579-1.907,17.185-3.008,27.222-2.05-2.187-3.719-3.534-4.85-5.238C177.215,480,138.48,421.307,99.333,362.892c-4.883-7.287-6.009-13.7-3.686-22.11,9.273-33.591,17.891-67.361,27.141-102.542,13.538,3.32,26.448,6.779,39.491,9.627q55.867,12.2,111.83,23.965c17.555,3.694,17.642,3.505,14.041,21.681-11.336,57.231-22.79,114.439-34.011,171.692-2.657,13.557-4.584,27.257-7.092,42.361"
      transform="translate(21.361 53.861)"
    />
    <path
      id="Path_45"
      data-name="Path 45"
      d="M584.291,345.145c-2.721,50.963-5.42,101.528-8.314,155.732L357.318,477.905c6.991-52.145,13.855-103.354,20.772-154.953l206.2,22.193"
      transform="translate(80.783 73.013)"
    />
    <path
      id="Path_46"
      data-name="Path 46"
      d="M351.521,185.184l192.885,24.232c-4.079,51.072-8.2,102.671-12.432,155.652L326.22,342.174c8.534-52.946,16.9-104.887,25.3-156.99"
      transform="translate(73.752 41.866)"
    />
    <path
      id="Path_47"
      data-name="Path 47"
      d="M99.087,107.6c7.772,7.309,15.89,11.813,26.933,7.406,2.479-.989,6.422.156,9.117,1.482,18.006,8.861,37.475,6.236,56.444,8.2,21.5,2.23,42.885,6.477,64.008,11.228,11.439,2.572,14.4,15.77,7.18,25.016-7.267,9.3-14.707,18.522-21.248,28.325-3.42,5.125-9,11.432.223,17.125,1.032.638,1.435,3.538,1.019,5.093-6.842,25.6-13.861,51.145-21.222,78.1-22.627-5.275-45.517-9.751-67.905-16.015-41.993-11.75-83.667-24.641-125.547-36.8-9.029-2.621-10.91-7.184-9.979-16.649,1.265-12.86,7.424-22.806,12.732-33.875C45.033,156.643,57.6,126.266,70.609,96.117c3.079-7.135,3.965-16.839,14.538-15.8,11.1,1.094,16.3,10.805,14.386,23.885-.167,1.139-.3,2.282-.445,3.394"
      transform="translate(4.046 18.141)"
    />
    <path
      id="Path_48"
      data-name="Path 48"
      d="M265.612,269.958l82.963,14.61c-2.057,14.029-4.044,27.565-6.287,42.859l59.378,7.513c-3.5,28.216-6.769,55.691-10.347,83.126-2.619,20.083-6.334,40.05-8.2,60.193-.973,10.522-6.547,9.957-13.65,8.743q-58.786-10.043-117.555-20.169c-7.024-1.209-14.023-2.569-23.126-4.243C241,398.726,253.162,335.084,265.612,269.958"
      transform="translate(51.725 61.032)"
    />
    <path
      id="Path_49"
      data-name="Path 49"
      d="M836.742,111.544l30.236,5.3-.184,4.175c-4.936,2.177-10.395,3.626-14.693,6.694-7.523,5.373-14.459,11.6-21.409,17.733-6.19,5.463-11.639,11.849-18.192,16.8-7.452,5.631-12.229,11.389-11.71,21.7.245,4.86-3.422,10.824-7,14.766-5.959,6.571-10.9,12.071-5.394,21.662,1.8,3.141.443,8.364-.125,12.546-2.054,15.114,1.7,20.572,14.372,28.613,10.542,6.691,19.888,15.474,28.963,24.167,4.221,4.045,5.267,10.175-3.635,10.3-44.953.63-89.911.945-134.868,1.317-.686.006-1.379-.688-3.742-1.948,0-18.07.424-37.063-.272-56.015-.2-5.322-3.736-10.508-5.644-15.789-.95-2.63-2.577-5.421-2.344-8,.793-8.76,3.824-17.575,3.176-26.156-1.34-17.736-4.258-35.372-6.978-52.98-2.62-16.96-5.807-33.832-9.059-52.556h54.395c1.234-5.612,2.188-9.95,3.313-15.065,2.043,1.393,3.768,2.048,3.742,2.627-.916,21.149,15.419,24.474,30.241,29.285,1.837.6,4.406,1.362,5.787.57,18.388-10.546,31.038,4.914,44.856,11.09,10.756,4.807,18.691,8.209,26.166-.841"
      transform="translate(151.076 15.557)"
    />
    <path
      id="Path_50"
      data-name="Path 50"
      d="M188.364,298.428c6.915-24.872,13.966-49.227,20.254-73.776,1.142-4.457-.839-9.713-1.378-14.6l-4.161-.535c8.887-12.29,17.463-24.822,26.781-36.776,5.235-6.718,7.117-11.113.781-19.405-4.24-5.548-4.334-16.181-2.825-23.86,6.423-32.685,14.135-65.115,21.676-98.981L270.6,34.747c-1.6,8.884-4.067,16.62-4.154,24.382-.148,13.162-1.564,27.321,2.667,39.251,4.706,13.272,15.353,24.386,22.915,36.742,2,3.263,3.665,8.011,2.84,11.443-1.653,6.871-6.138,13.1-7.511,19.988-.953,4.779.055,11.053,2.678,15.013,1.492,2.252,7.992,1.193,12.17,1.62,5.5,14.534,11.368,30.274,17.5,45.912.754,1.921,2.856,4.6,4.465,4.7,12.328.734,24.693,1.193,37.037,1.045,2.618-.031,5.2-2.9,10.1-5.851.752,7.491,2.677,13.979,1.791,20.059q-5.488,37.711-12.673,75.162c-.6,3.165-5.906,8.29-8.163,7.868-53.125-9.92-106.131-20.473-159.146-30.967-1.081-.215-2.028-1.113-4.757-2.681"
      transform="translate(42.585 6.894)"
    />
    <path
      id="Path_51"
      data-name="Path 51"
      d="M751.385,396.393,556.5,391.644c.636-13.771,1.211-26.217,1.824-39.492L497.5,346.695c2.258-23.977,4.529-46.812,6.494-69.673.663-7.729,5.666-7.168,11.049-6.837q47.663,2.93,95.335,5.726c11.522.665,23.067,1.563,34.586,1.379,10.839-.173,21.2-.609,29.943,7.727,1.974,1.882,6.631,1.971,9.8,1.426,21.366-3.679,35.421,4.5,41.668,25.781,6.679,22.749,12.48,45.752,18.882,68.583,1.349,4.811,3.644,9.357,6.123,15.586"
      transform="translate(112.476 61.047)"
    />
    <path
      id="Path_52"
      data-name="Path 52"
      d="M503.6,285.9c3.047-38.141,6-75.148,8.964-112.2l205.574,9.847c-9.405,8.5-6.618,15.175,1.1,21.984,2.139,1.887,2.538,6.493,2.588,9.87q.386,25.652.022,51.31c-.049,3.3-1.985,6.526-2.588,9.87A63.953,63.953,0,0,0,717.909,289c.119,6.219.921,12.424,1.287,16.866l-37.98-2.429c-.792-.05-1.8.193-2.347-.2-24.534-17.752-53.066-7-79.554-10.634-30.988-4.25-62.516-4.562-95.719-6.707"
      transform="translate(113.853 39.27)"
    />
    <path
      id="Path_53"
      data-name="Path 53"
      d="M716.82,201.283l-205.327-9.971c3.461-40.077,6.719-77.814,10.108-117.056,17.262,1.233,33.349,2.783,49.474,3.458,39.7,1.663,79.406,3.167,119.124,4.164,9.329.235,11.949,3.564,12.3,12.651.474,12.391,3.169,24.687,4.723,37.048,1.085,8.635,1.611,17.345,2.823,25.962,1.916,13.635,4.2,27.22,6.78,43.744"
      transform="translate(115.639 16.788)"
    />
    <path
      id="Path_54"
      data-name="Path 54"
      d="M784.036,609.091c-5.237-1.54-8.207-1.707-10.309-3.145-15.221-10.416-30.094-4.817-45.841-.98-7.376,1.8-16.3-2.751-26.035-4.732-2.959,4.31-7.328,10.2-14.451.738-1.536-2.042-6.545-1.627-9.991-2.013-1.431-.161-4.117,1.1-4.275.777-6.694-13.868-21.244-6.652-31.06-11.757-4.091-2.126-7.35-5.82-11.39-8.083-3.068-1.719-6.909-3.533-10.169-3.171-12.412,1.38-15.749-4.988-14.953-15.721q2.288-30.875,4.524-61.756c.167-2.338.022-4.7.022-8.432l-97.818-5.009c.293-5.851.507-10.092.757-15.091l263.625,8.988c2.386,41.916,4.831,84.881,7.364,129.384"
      transform="translate(115.819 106.42)"
    />
    <path
      id="Path_55"
      data-name="Path 55"
      d="M705.262,356.71c16.626,0,33.261.31,49.881-.081,19.487-.459,38.972-1.324,58.435-2.425,8.679-.49,13.044,3.451,12.688,11.583-.674,15.369,7.056,26.245,17.252,36.395,4.2,4.177,6.544,10.384,11.063,14.012s10.706,5.2,16.7,7.917c-.637,2.5-1.177,5.135-1.975,7.683-5.016,15.991-1.746,25.42,13.859,31,9.432,3.371,10.717,9.853,13.171,17.424,1.913,5.9,6.406,10.937,8.7,16.77,1.145,2.918.784,7.1-.4,10.115-2.472,6.278-6.731,11.905-8.8,18.272-2.289,7.036-5.072,11.578-13.834,8.886.12-.808.244-1.764.4-2.715.722-4.259,3.1-9.24,1.652-12.543-1.117-2.544-7.066-4.221-10.83-4.159-40.585.679-81.162,1.788-121.741,2.753-3.936.094-7.875.013-13.458.013-.477-8.288-1.215-16.026-1.314-23.772-.305-23.808-.1-47.627-.772-71.423-.117-4.136-2.918-8.64-5.567-12.159-4.166-5.537-10.722-9.764-4.427-18.125.766-1.02-2.57-6.763-5.2-8.406-10.141-6.335-15.255-13.714-15.478-27.012"
      transform="translate(159.446 80.069)"
    />
    <path
      id="Path_56"
      data-name="Path 56"
      d="M66.809,90.865c1.819-7,3.427-13.193,5.036-19.382q-1.556-.728-3.113-1.454l6.716-2.761C65.97,48.238,70.877,28.657,70.253,9.1c13.683,12.646,29.36,17.528,45.619,21.921,5.114,1.382,9.039,7.154,14.268,11.556V0L271.419,36.463c-8.021,37.167-16.084,74.519-24.265,112.423-6.45-1.791-14.3-5.181-22.416-5.991-21.407-2.137-42.946-2.919-64.39-4.74-8.155-.692-16.173-2.874-24.291-4.14-9.464-1.477-19.225-1.791-28.353-4.383-5.1-1.448-12.353-5.857-13.183-10.02-4.214-21.117-6.856-24.658-27.711-28.747"
      transform="translate(15.104 0)"
    />
    <path
      id="Path_57"
      data-name="Path 57"
      d="M1139.692,628.685c-3.475,16.838-6.8,32.941-10.256,49.7H1111.78v11.552c-26.943,1.62-53.495,3.459-80.075,4.723-16.034.763-23.2-5.674-24.1-21.936-.636-11.6.278-23.289.319-34.94a20.925,20.925,0,0,0-1.323-6.709c-6.539-18.979-13.29-37.885-19.73-56.9-3.306-9.756-6.246-19.643-9.07-29.549-1.262-4.425-1.831-9.046-3.01-15.094l64.773-7.894c6.388,7.439,10.386,12.61,14.937,17.244,4.149,4.223,8.842,7.922,13.392,11.739,6.249,5.242,13.417,9.65,18.723,15.7,11.693,13.34,28.437,21.888,34.543,40.869,2.771,8.613,12.635,14.951,18.53,21.487"
      transform="translate(220.382 117.933)"
    />
    <path
      id="Path_58"
      data-name="Path 58"
      d="M932.277,667.233c8.5-1.121,16.965-2.337,25.453-3.331,15-1.757,30.018-3.4,45.034-5.049,7.247-.8,12.984.168,19.275,5.879,4.626,4.2,13.9,4.453,21.09,4.425,14.288-.055,28.558-1.921,42.855-2.28,13.043-.328,26.111.347,40.187.6V654.543c10.091-2.2,16.233-.027,19.782,11.436,7.219,23.32,18.5,44.7,36.518,61.883,1.413,1.349,3.718,3.172,3.563,4.544-2.456,21.824,15.605,34.388,23.326,51.557,8.688,19.326,12.142,38.5,5.737,59.005a15.633,15.633,0,0,0-.855,7.055c1.537,8.606-2.455,12.434-10.481,13.954-4.129.782-8.031,2.766-12.951,4.538-.883-2.775-1.371-5.36-2.495-7.634-2.439-4.931-4.061-12.3-8.048-14.051-12.092-5.293-22.246-11.584-26.572-24.743-18.252-3.209-18.638-22.805-29.713-32.676-1.5-1.336-1.352-5.7-.531-8.182,1.329-4.018,3.85-7.641,6.778-13.141l-17.06-4.132c.55-6.851,1.461-14.436,1.707-22.044.4-12.589-4.82-20.8-17.069-26.331-8.139-3.673-14.42-11.6-21.306-17.874-14.956-13.626-21.032-13.966-35.593-1.91-3.645,3.017-7.346,6.544-11.648,8.031-4.019,1.388-10.629,2.327-13.075.12-21.651-19.533-45.809-23.662-72.868-14.026a3.153,3.153,0,0,1-1.716-.2l-9.324-22.483"
      transform="translate(210.77 147.806)"
    />
    <path
      id="Path_59"
      data-name="Path 59"
      d="M904.907,497.466l-24.691,7.116c-1.616-5.395-3-12.941-6.206-19.606-1.883-3.91-6.187-7.542-10.266-9.343-11.5-5.078-17.418-13.825-14.59-23.588,5.191-17.917,5.191-17.917-10-21.168-5-6.161-8.23-10.538-11.875-14.536-4.8-5.266-10.715-9.689-14.746-15.455-7.016-10.03-5.327-22.06,1.966-32.817,5.033-7.426,12.479-14.68,6.442-25.445-.8-1.432,1.343-6.1,3.307-7.251,21.526-12.592,23.364-19.03,10.664-39.691a39.76,39.76,0,0,1-2.018-4.579l74.066-4.893c18.863,28.5,14.034,61.625,18.123,92.985,2.228,17.084,3.385,34.311,4.849,51.488.229,2.68.528,6.267-.918,8.032-9.468,11.557-9.923,26.136-14.3,39.414-2.484,7.538-7.207,14.339-9.81,19.339"
      transform="translate(182.687 64.706)"
    />
    <path
      id="Path_60"
      data-name="Path 60"
      d="M784.181,158.02l35.28-11.892-1.913,8.076c8.134,4.253,14.98,8.086,22.058,11.428,3.487,1.648,7.4,2.382,11.123,3.545,17.235,5.385,34.726,10.1,51.58,16.485,7.192,2.726,11.1,24.3,7.672,31.455-2.386,4.977-4.073,10.287-6.073,15.449l4.637,2.91,18.416-23.466c-2.277,9.973-4.936,20.4-6.987,30.947-2.136,10.982-4.63,22.027-5.221,33.137-.592,11.138.972,22.39,1.746,36.4-20.043,1.338-41.146,3.085-62.284,3.97a83.808,83.808,0,0,1-22.138-2.533c-9.062-2.1-13.589-7.382-9.5-17.461,2.39-5.9-9.268-33.058-14.8-36.979-11.273-7.993-23.478-15.153-33-24.9-4.019-4.116-2.458-13.891-2.932-21.127-.168-2.592,2.594-6.394,1.552-7.75-9.226-12-1.694-18.3,7.163-25.152,2.136-1.65,2.077-6.458,2.532-9.878.661-4.975.874-10.011,1.087-12.659"
      transform="translate(173.897 33.037)"
    />
    <g
      id="stloutskirts"
      class="hotspot"
      data-name="Component 7 – 2"
      transform="translate(948.186 445.437)"
    >
      <path
        :class="active === 'stloutskirts' ? 'active hotspot' : 'hotspot'"
        @click="hotspotClicked('stloutskirts')"
        id="Exclusion_1"
        data-name="Exclusion 1"
        d="M73.093,146.127a73.288,73.288,0,1,1,27.924-5.579A72.953,72.953,0,0,1,73.093,146.127ZM72.62,34.395a40.728,40.728,0,1,0,22.372,6.693A40.478,40.478,0,0,0,72.62,34.395Z"
        stroke="#fff"
        stroke-miterlimit="10"
        stroke-width="2"
      />
    </g>
    <path
      id="Path_61"
      data-name="Path 61"
      d="M835.5,374.573c-5.91-1.481-10.061-3.505-14.153-3.391-35.374.98-70.745,2.138-106.094,3.747-8.434.384-10.342-3.413-12.159-10.713-5.615-22.562-12.306-44.86-18.622-67.248-.732-2.594-2.6-5.167-2.442-7.658.535-8.672,1.724-17.3,2.874-27.874,1.371-.068,5.54-.385,9.714-.462,42.151-.77,84.305-1.447,126.456-2.274,12.76-.25,14.539,2.341,14.495,14.536-.02,5.6,3.3,12.028,6.95,16.623,5.184,6.533,12.34,11.458,18.159,17.544,10.17,10.641,6.427,22.971-7.451,28.98-4.139,1.793-10.28,8.885-9.429,10.687,6.205,13.137-3.032,20.288-8.3,27.5"
      transform="translate(154.191 58.483)"
    />
    <path
      id="Path_62"
      data-name="Path 62"
      d="M867.762,508.187l17.9,1.989c-3.427,5.646-6.292,10.423-9.236,15.152-.4.638-1.721,1.048-1.709,1.54.688,26.492-22.311,42.77-28.457,66.067-2.694,10.211-1.67,21.4-2.456,34.1H753.4c-3.622-6.243,4.17-22.345-15.143-17.619-1.452-38.715-2.873-76.614-4.335-115.624a76.065,76.065,0,0,1,7.628-.916c39.357-1.383,78.716-2.772,118.08-3.925,4.461-.131,8.963,1.172,13.446,1.811-1.494,3.693-3.125,7.339-4.421,11.1-.6,1.752-.554,3.729-.9,6.32"
      transform="translate(165.926 110.541)"
    />
    <path
      id="Path_63"
      data-name="Path 63"
      d="M1007.078,533.781c12.1-8.813,24.265-17.526,36.265-26.467,11.838-8.823,23.755-17.576,35.093-27.013,3.352-2.791,4.86-7.794,6.481-10.541l182.14-33.168c2.877,8.259,9.164,18.321,9.248,28.434.121,14.7-10.511,20.442-26.378,15.05,2.583,4.477,4.966,7.016,5.459,9.881.572,3.317-.52,6.92-.863,10.141l8.762,6.23c-3.358,1.906-6.562,4.18-10.1,5.657-14.828,6.2-27.115,14.8-32.258,31.082-3.334,10.554-15.236,14.041-24.235,7.26-3.806-2.869-7.279-6.178-11.083-9.05-15.265-11.521-31.182-20.257-51.6-14.926-3.581.934-8.282-2.074-12.373-3.526-3.262-1.157-6.409-3.965-9.56-3.9-18.955.423-38.021-.7-55.652,9.451-14.641,8.431-31.2,9.724-47.68,9.523q-.831-2.062-1.664-4.122"
      transform="translate(227.68 98.705)"
    />
    <path
      id="Path_64"
      data-name="Path 64"
      d="M828.917,686.844h62.2c2.979,6.932,6.478,12.911,8.03,19.356,1.282,5.321.262,11.2.262,20.02l12.89-1.4c-.791,1.214-2.314,3.5-3.785,5.82-1.967,3.1-5.393,6.258-5.325,9.327.048,2.173,4.593,4.755,7.587,6.235,4.756,2.352,9.893,3.936,14.869,5.843l-2.229,4.935-28.656-13.632c-1.585,2.706-4.329,7.392-5.3,9.052-6.835,1.549-12.55,1.495-16.641,4.044-9.356,5.83-17.545,3.692-20.507-6.59-2.281-7.911-24.3-19.177-31.844-15.585-2.847,1.356-4.383,5.461-7.049,9.022L760.3,734.21c2.121-11.849,3.657-25.451,7.128-38.539,4.317-16.277-7.791-27.533-10.762-41.461-3.165-14.826-5.874-29.748-9.224-46.889,14.313,0,24.92.218,35.513-.052,15.911-.4,31.825-.948,47.714-1.846,6.719-.38,8.114,2.674,11.17,8.493,9.047,17.226,2.244,30.424-5.861,45.078-4.4,7.955-4.758,18.144-7.065,27.85"
      transform="translate(168.983 136.867)"
    />
    <path
      id="Path_65"
      data-name="Path 65"
      d="M1269.845,305.891c-12.48-4.638-22.141-7.97-31.551-11.9-2.594-1.081-5.532-3.549-6.446-6.058-4.2-11.552-12.742-13.721-23.335-11.541-35.034,7.209-70.026,14.62-105.055,21.86-3.791.783-7.705.961-15.2,1.853,2.95-6.178,4.321-10.206,6.639-13.584,2.429-3.539,5.754-6.464,8.714-9.635,6.2-6.649,8.233-13.088-.105-19.856-1.511-1.226-1.565-4.243-3.052-8.73,10.625-2.263,20.456-4.725,30.422-6.367,7.889-1.3,16.646.128,23.745-2.768,8.969-3.66,17.047-9.982,24.708-16.147,3.735-3.006-1.64-18.871-6.774-22.431,15.665-28.738,34.331-53.217,73.161-49.188,3.112,13.963,6.422,29.607,10.2,45.139.822,3.377,3.911,6.17,4.926,9.541,2.253,7.486,4.728,15.077,5.541,22.79,1.693,16.07,2.556,32.239,3.38,48.385.437,8.546.077,17.133.077,28.634"
      transform="translate(246.035 34.13)"
    />
    <path
      id="Path_66"
      data-name="Path 66"
      d="M1087.55,371.179c-5.543-28.629-11.453-54.932-15.052-81.548-.663-4.9,8.038-11.06,13.5-18.021,2.114,4.588,3.3,7.168,4.987,10.82,37.81-7.761,75.9-15.5,113.942-23.425,16.1-3.354,26.647.727,31.672,15.838,2.017,6.063-.56,14.637-3.328,21.091-5.342,12.465,2.724,17.643,10.543,23.535,1.547,1.166,2.993,2.468,4.487,3.71-7.206,15.073-19.946,20.83-34.9,23.676-41.3,7.859-82.557,15.939-125.854,24.324"
      transform="translate(242.463 58.308)"
    />
    <path
      id="Path_67"
      data-name="Path 67"
      d="M907.112,535.13l76.948-6.681c6.691,24,13.086,47.6,19.909,71.067a219.917,219.917,0,0,0,8.646,23.249c3.025,7.231,4.829,13.721,3.524,22.364-1.764,11.669,1.014,24.023,1.832,35.925-25.663,3.126-51.307,6.067-76.891,9.454-10.624,1.408-12.131,5.542-4.893,13.473,5.709,6.256,6.112,11.705.777,18.618l-12.906-18.99-8.928,16.314c-15.591-62.4-6.652-123.341-8.017-184.795"
      transform="translate(204.779 119.472)"
    />
    <path
      id="Path_68"
      data-name="Path 68"
      d="M1113.858,462.58c-2.468,12.792-12.765,15.457-21.134,20.2-3.85,2.181-8.11,3.845-11.5,6.59-6.6,5.351-11.93,12.781-19.222,16.648a55.351,55.351,0,0,0-24.061,24.067c-1.016,1.935-4.172,3.666-6.485,3.87Q944.7,541.6,857.909,548.792c-1.081.092-2.227-.553-5.966-1.563,3.394-9.467,6.783-18.464,9.837-27.571,2.993-8.923,3.061-21.269,9.166-26.027,6.221-4.849,18.378-1.472,27.669-3.166,12.887-2.348,25.365-7.439,38.3-8.948,43.439-5.069,87.01-9.008,130.516-13.514,15.29-1.584,30.543-3.556,46.428-5.423"
      transform="translate(192.608 104.58)"
    />
    <path
      id="Path_69"
      data-name="Path 69"
      d="M924.961,332.861c1.87-5.707,2.933-9.494,4.338-13.15,5.656-14.712,7.983-29.809-1.866-43.2-7.172-9.751-6.831-20.324-5.662-30.441,1.648-14.245,6.033-28.174,9.25-42.237l3.934.585,5.465,11.239c3.169-5.943,7.158-9.924,6.873-13.573-.715-9.138,1.614-14.688,12.743-15.4l-7.593-8.5c8.164-12.34,17.632-2.105,26.162-1.416,4.457.362,8.518,4.209,13.041,5.46,5.992,1.654,5.932,5.985,8.389,10.55,10.168,18.9,3.67,33.191-7.114,48.239-1.308,1.826,2.979,7.656,4.673,11.631,3.139-1.6,6.732-2.659,9.318-4.891,5.315-4.586,10.089-9.8,15.086-14.749,3.315,6.443,6.838,12.792,9.872,19.365a55.885,55.885,0,0,1,4.733,13.459c1.387,7.922,7.723,16.23-4.585,22.919-4.654,2.528-5.287,12.391-7.822,18.885-3.607,9.237-6.861,17.766-19.669,18.471-25.893,1.425-51.706,4.3-79.564,6.748"
      transform="translate(208.247 38.917)"
    />
    <path
      id="Path_70"
      data-name="Path 70"
      d="M980.274,409.634c-3.693-32.914-7.387-65.828-11.122-99.121,22.25-5.55,42.039-6.98,63.511-.148,8.993,2.862,22.506-5.554,32.9-10.908,8.139-4.193,14.635-11.569,21.86-17.531l4.125,1.191c1.009,17.768,2.15,35.529,2.969,53.306.4,8.655.426,17.343.2,26-.266,10.389-6.567,15.8-14.668,22.122-8.765,6.844-14.14,18.284-20.193,28.208-2.488,4.079-2.885,9.432-4.853,16.4l-12.692-9.628c-13.412,0-24.585.09-35.754-.092-1.768-.028-3.64-1.061-5.251-2-6.011-3.492-11.933-7.137-17.888-10.725l-3.144,2.916"
      transform="translate(219.106 63.738)"
    />
    <path
      id="Path_71"
      data-name="Path 71"
      d="M874.279,503c1.474-23.265,1.474-23.265,20.517-19.939,3.113-6.1-3.287-18.043,10.153-17.711-2.874-18.894,16.634-23.031,26.326-17.2l21.325-4.394,3.63-7.291c9.446,3.905,13.889,1.779,18.334-8.555,4.013-9.329,15.066-15.547,22.665-23.47a30.057,30.057,0,0,0,6.464-11.59c5.362-3.154,10.931-5.1,15.293,3.872,1.582,3.253,8.727,4.827,13.514,5.283,8.66.824,17.456.233,29.87.233L1089.1,444.19c-5.92,7.772-12.329,18.39-20.96,26.71-5.288,5.1-13.754,8.657-21.179,9.5-43.8,4.953-87.714,8.887-131.582,13.239-2.608.257-7.039.614-7.415,1.88-2.477,8.315-9.193,6-14.679,6.488-6.294.559-12.625.678-19,.992"
      transform="translate(197.657 88.218)"
    />
    <path
      id="Path_72"
      data-name="Path 72"
      d="M1165.373,359.224c5.69-1.132,13.047,10.474,18.386-2.4,1.188,2.065,1.749,4.6,3.081,5.105,9.908,3.762,19.8,6.975,12.387,21.2-.634,1.215,3.836,6.473,6.86,7.918,6.718,3.211,13.978,5.331,21.1,7.635,6.12,1.979,8.029,5.949,8.344,12.277.492,9.909,2.27,19.756,3.507,29.66l16.627,3.69q-.248,1.782-.5,3.565a1399.575,1399.575,0,0,1-221.289,37.635c4.178-6.051,7.625-11.613,11.656-16.714,3.911-4.949,8.423-9.422,12.592-14.017,14.826,9.252,27.31,8.265,39.96-2.381a22.074,22.074,0,0,1,8.4-4.129c8.074-2.129,10.363-7.294,11.995-15.288,2.259-11.062,7.484-21.519,11.626-32.735,7.522,2.433,12.733,1.527,15.1-8.319,1.317-5.47,7.8-9.513,11.093-14.741,3.521-5.591,6.028-11.818,9.073-17.961"
      transform="translate(233.739 80.671)"
    />
    <path
      id="Path_73"
      data-name="Path 73"
      d="M959.633,451.873,903.8,464.694l-3.087-3.049c3.121-6.7,6.637-13.244,9.23-20.137,1.875-4.983,3.889-10.53,3.5-15.646-2.791-36.641-6.186-73.237-9.592-112.184l75.275-11.372c2.092,17.481,4.12,33.048,5.776,48.656,2.178,20.532,4.231,41.082,5.869,61.662.212,2.688-1.672,7.3-3.746,8.13-14.713,5.91-18.672,20.473-27.386,31.119"
      transform="translate(203.633 68.345)"
    />
    <path
      id="Path_74"
      data-name="Path 74"
      d="M1359.476,105.372c1.944,7.724.52,16.84,13.609,14.822,6.38-.983,9.094,12.48,2.36,16.943-7.737,5.127-10.944,17.534-24.193,14.989-2.9-.558-7.084,5.56-10.951,8.836l-8.212-2.7c-.23,2.692-.8,5.407-.633,8.074.768,11.932-8.385,22.365-20.311,24.827-3.247.671-6.908,5.233-8.073,8.768-2.038,6.19-2.292,12.967-6.2,20.863-3.5-6.145-7.828-11.974-10.3-18.505-4.339-11.471-6.7-23.725-11.416-35.011-6.648-15.9-2.958-30.257,3.19-45.047,1.758-4.232,1.145-9.562,1.149-14.39.013-20.366,3.606-39.906,13.129-59.312,6.157,6.794,11.38,8.145,18.305.389,6.991-7.827,18.977-4.234,22.475,5.95,4.121,12,7.775,24.183,10.945,36.465,2.131,8.258,4.751,15.133,15.125,14.036"
      transform="translate(287.489 10.101)"
    />
    <path
      id="Path_75"
      data-name="Path 75"
      d="M1036.13,529.145c8.9-11.784,22.527-17.621,37.435-20.148,9.709-1.647,19.792-1.175,29.714-1.412,2.258-.054,5.889.154,6.6,1.474,5.3,9.837,13.839,7.51,21.652,5.739,12.989-2.943,24.113-.037,34.5,7.942,8.133,6.245,16.708,11.913,23.38,16.631-4.442,11.566-6.79,22.544-12.5,31.341-7.41,11.411-17.25,21.314-26.553,31.4-3.9,4.228-8.7,6.378-11.067,13.205-4,11.507-11.287,10.546-18.371,1.474-16.142-20.674-30.4-43.364-54.308-56.684a4.393,4.393,0,0,1-1.049-.552c-9.4-9.692-18.789-19.4-29.434-30.407"
      transform="translate(234.249 114.753)"
    />
    <path
      id="Path_76"
      data-name="Path 76"
      d="M1052.382,397.736c3.263.663,7.943,1.436,6.719-7.695-.512-3.828,2.016-10.812,4.861-11.889,16.629-6.3,20.513-19.388,21.088-34.921.055-1.471.94-2.913,1.857-5.587,1.507,7.032,2.737,12.768,4.19,19.549l28.916-3.131c1.618,7.2,3.051,13.581,4.55,20.26,10.556-6.946,20.545-13.518,30.534-20.088q2.246,1.948,4.492,3.9c-2.976,6.877-5.221,14.207-9.122,20.514-4.475,7.235-10.42,13.563-16.365,21.093l-8.3-4.969c-5.056,16.019-9.82,31.134-14.609,46.242-.345,1.091-.573,2.708-1.35,3.1-9.117,4.6-18.358,8.958-27.508,13.5-18.761,9.307-24.6-8.354-34.7-16.783a29.785,29.785,0,0,1-7.685-10.322c-1.258-2.772-1.668-6.978-.45-9.622,3.4-7.375,7.835-14.269,12.879-23.143"
      transform="translate(234.846 76.334)"
    />
    <path
      id="Path_77"
      data-name="Path 77"
      d="M883.032,123.67c-4.676,8.847-8.753,16.563-12.831,24.278l2.518,2.651c11.563-8.983,19.075-5.785,28.231,4.217,4.5,4.915,17.06,5.655,24.817,3.7,13.725-3.451,26.568-10.4,40.707-16.276,3.286,21.849,16.353,9.036,25.785,9.549l4.654,17.3c-9.351,0-18.785-1.287-27.695.321-11.524,2.079-22.635,6.458-33.915,9.877-2.754.835-5.426,2.381-8.212,2.591-10.75.812-16.571,6.122-17.855,17.007-.365,3.09-2.955,5.918-6.175,9.725-.2-24.589-17.739-27.861-35.065-31.6-10.818-2.334-21.614-4.849-32.267-7.822-3.321-.927-6.15-3.618-8.995-5.373l56.3-40.146"
      transform="translate(186.909 27.959)"
    />
    <path
      id="Path_78"
      data-name="Path 78"
      d="M1243.252,232.308c-8.021-27.147-15.92-53.88-24.214-81.95l44.121-11.772c3.774,10.629,11.59,20.952-3.688,28.387-.97.472-1.539,2.727-1.51,4.136.335,16.477.631,32.958,1.33,49.422.474,11.154-5.426,13.59-16.038,11.776"
      transform="translate(275.601 31.332)"
    />
    <path
      id="Path_79"
      data-name="Path 79"
      d="M1263.071,126.513c3.844,5.7,6.43,8.252,7.482,11.327,3.839,11.224,6.724,22.783,10.8,33.912,5.1,13.939,11.022,27.578,17.959,44.731l-43.951,18.7V175.521c17.707-12.3,3.208-30.342,7.71-49.008"
      transform="translate(283.812 28.602)"
    />
    <path
      id="Path_80"
      data-name="Path 80"
      d="M1144.132,345.289l64.448-12.527c3.332,12.695,7.214,24.334,9.288,36.287,1.947,11.221,7.286,14.836,17.913,11.877a36.079,36.079,0,0,1,5.638-.674l2.6,2.954-11.869,12.88c-8.143-3.047-13.281-4.274-17.629-6.838-2.895-1.708-6.04-5.04-6.673-8.135a168.926,168.926,0,0,1-2.944-25.7c-.189-3.96,1.406-8.006,2.255-12.326-15.831,6.669-16.243,11.509-4.528,52.273l-24.693-3.3c2.1-7,4.449-12.43,5.1-18.061.319-2.766-2.525-5.9-3.937-8.861-10.766,5.872-14.492-5.581-21.409-8.974-4.39-2.153-7.852-6.19-13.553-10.883"
      transform="translate(258.666 75.231)"
    />
    <path
      id="Path_81"
      data-name="Path 81"
      d="M1231.8,361.254c-7.011-3.011-13.1-4.89-18.384-8.085-6.8-4.109-9.324-9.073-1.829-15.919,5.39-4.923,9.311-11.452,14.245-17.718l-17.977-16.845c1.736-9.427,3.447-18.721,5.161-28.028,29.436,5.689,31.3,7.778,21.753,28.694,10.01,3.355,14.477,17.008,9.27,32.125-3.05,8.855-8.018,17.05-12.239,25.777"
      transform="translate(272.884 62.095)"
    />
    <path
      id="Path_82"
      data-name="Path 82"
      d="M1330.384,244.114l-20.289,5.568c.97,1.129.011.342-.532-.669-6.057-11.277-13.665-14.9-26.974-10.073-13.92,5.043-29.113,6.578-43.712,9.639-4.9-21.4-3.411-23.71,15.574-27.619,11.261-2.319,22.581-4.6,33.56-7.935,5.385-1.634,10.056-5.618,16.924-9.635.537,3.249,2.225,6.769,1.351,9.458-2.333,7.173-4.754,13.077,6.588,14.131,2.587.239,4.667,4.956,7.163,7.428,3.4,3.37,6.98,6.564,10.349,9.708"
      transform="translate(279.547 45.982)"
    />
    <path
      id="Path_83"
      data-name="Path 83"
      d="M1282.9,236.587c1.371,6.46,3.362,12.105,3.45,17.779.047,2.985-2.5,7.442-5.133,8.795-12.986,6.671-26.39,12.526-38.991,18.364-.8-10.293-1.729-22.258-2.763-35.6l43.438-9.337"
      transform="translate(280.218 53.488)"
    />
    <path
      id="Path_84"
      data-name="Path 84"
      d="M1229.789,372.494c-4.419,1.347-6.968,2.4-9.621,2.851-2.272.385-4.662.08-8.03.08q-4.727-18.1-9.477-36.285l1.289-1.143c8.387,11.2,16.773,22.394,25.839,34.5"
      transform="translate(271.898 76.414)"
    />
    <path
      id="Path_85"
      data-name="Path 85"
      d="M538.581,488.975c2.484-39.982,4.793-77.13,7.2-115.889,4.579,0,8.352-.078,12.12.011,58.006,1.39,116.018,2.615,174.012,4.4,8.968.277,17.853,3.169,26.775,4.853l-3.127,3.949c1.745,6.036,1.692,13.916,5.607,17.725,8.147,7.927,8.964,17.409,9.216,27.074.538,20.587.164,41.2.164,65.107l-231.968-7.235"
      transform="translate(121.763 84.34)"
    />
    <path
      id="Path_86"
      data-name="Path 86"
      d="M1281.267,269.615c-11.322,13.2-24.442,23.6-42.624,25.064l-1.959-3.717,44.583-21.346"
      transform="translate(279.59 60.955)"
    />
    <path
      id="Path_87"
      data-name="Path 87"
      d="M1298.6,248.02l-14.317,10.908c-1.737-8.014-3.26-15.042-4.876-22.5,12.516-5.259,13.337,6.443,19.193,11.59"
      transform="translate(289.249 53.152)"
    />
    <path
      id="Path_88"
      data-name="Path 88"
      d="M1112.058,362.994c-.742-3.736-1.465-7.374-2.333-11.747l29.5-5.869q.122.64.244,1.281l-27.408,16.335"
      transform="translate(250.887 78.083)"
    />
    <path
      id="Path_89"
      data-name="Path 89"
      d="M924.525,718.061l-36.034,4.751c-1.936-6.091-4.906-15.431-7.971-25.068H818.288c2.586-8.39,3.667-16.986,7.688-23.867,8.065-13.8,9.519-25.773,6.478-42.854-3.985-22.381-9.426-49.1,13.086-68.913,5.476-4.82,7.038-13.977,10.789-20.93a7.52,7.52,0,0,1,5.1-3.516c19.367-1.334,38.755-2.352,58.435-3.473q2.343,92.464,4.658,183.871"
      transform="translate(184.999 120.77)"
    />
    <g
      id="Nashville"
      data-name="Component 6 – 2"
      transform="translate(1115.264 546.543)"
    >
      <path
        :class="active === 'nashville' ? 'active hotspot' : 'hotspot'"
        @click="hotspotClicked('nashville')"
        id="nashville"
        data-name="Path 92"
        d="M891.124,426.543a47.364,47.364,0,1,1-47.364-47.362,47.362,47.362,0,0,1,47.364,47.362"
        transform="translate(-796.397 -379.181)"
        stroke="#fff"
        stroke-width="2"
      />
    </g>
    <g
      id="SW-Missouri"
      data-name="Component 10 – 2"
      transform="translate(872.494 565.098)"
    >
      <path
        :class="active === 'swmissouri' ? 'active hotspot' : 'hotspot'"
        @click="hotspotClicked('swmissouri')"
        id="swmissouri"
        data-name="Path 92"
        d="M854.014,407.988a28.808,28.808,0,1,1-28.808-28.807,28.808,28.808,0,0,1,28.808,28.807"
        transform="translate(-796.397 -379.181)"
        stroke="#fff"
        stroke-width="2"
      />
    </g>
    <g
      id="little-rock"
      data-name="Component 9 – 2"
      transform="translate(944.179 621.318)"
    >
      <path
        :class="active === 'littlerock' ? 'active hotspot' : 'hotspot'"
        @click="hotspotClicked('littlerock')"
        id="littlerock"
        data-name="Path 92"
        d="M854.014,407.988a28.808,28.808,0,1,1-28.808-28.807,28.808,28.808,0,0,1,28.808,28.807"
        transform="translate(-796.397 -379.181)"
        stroke="#fff"
        stroke-width="2"
      />
    </g>
    <g
      id="dallas"
      data-name="Component 11 – 2"
      transform="translate(771.955 728.568)"
    >
      <path
        @click="hotspotClicked('dallas')"
        :class="active === 'dallas' ? 'active hotspot' : 'hotspot'"
        id="dallas"
        data-name="Path 92"
        d="M891.124,426.543a47.364,47.364,0,1,1-47.364-47.362,47.362,47.362,0,0,1,47.364,47.362"
        transform="translate(-796.397 -379.181)"
        stroke="#fff"
        stroke-width="2"
      />
    </g>
    <g
      id="cincinatti"
      data-name="Component 3 – 2"
      transform="translate(1209.287 409.387)"
    >
      <path
        :class="active === 'cincinatti' ? 'active hotspot' : 'hotspot'"
        @click="hotspotClicked('cincinatti')"
        id="cincinatti"
        data-name="Path 92"
        d="M891.124,426.543a47.364,47.364,0,1,1-47.364-47.362,47.362,47.362,0,0,1,47.364,47.362"
        transform="translate(-796.397 -379.181)"
        stroke="#fff"
        stroke-width="2"
      />
    </g>
    <g
      id="indianapolis"
      data-name="Component 4 – 2"
      transform="translate(1132.978 432.748)"
    >
      <path
        :class="active === 'indianapolis' ? 'active hotspot' : 'hotspot'"
        @click="hotspotClicked('indianapolis')"
        id="indianapolis"
        data-name="Path 92"
        d="M838.928,400.446a21.265,21.265,0,1,1-21.266-21.265,21.265,21.265,0,0,1,21.266,21.265"
        transform="translate(-796.397 -379.181)"
        stroke="#fff"
        stroke-width="2"
      />
    </g>
    <g
      id="louisville"
      data-name="Component 5 – 2"
      transform="translate(1161.044 496.438)"
    >
      <path
        :class="active === 'louisville' ? 'active hotspot' : 'hotspot'"
        @click="hotspotClicked('louisville')"
        id="louisville"
        data-name="Path 92"
        d="M833,397.481a18.3,18.3,0,1,1-18.3-18.3,18.3,18.3,0,0,1,18.3,18.3"
        transform="translate(-796.397 -379.181)"
        stroke="#fff"
        stroke-width="2"
      />
    </g>
    <g
      id="kansas-city"
      data-name="Component 13 – 2"
      transform="translate(867.933 466.519)"
    >
      <path
        @click="hotspotClicked('kansascity')"
        :class="active === 'kansascity' ? 'active hotspot' : 'hotspot'"
        id="kansascity"
        data-name="Path 92"
        d="M848.851,405.407a26.227,26.227,0,1,1-26.227-26.226,26.226,26.226,0,0,1,26.227,26.226"
        transform="translate(-796.397 -379.181)"
        stroke="#fff"
        stroke-width="2"
      />
    </g>
    <g
      id="chicago"
      data-name="Component 14 – 2"
      transform="translate(1054.038 337.053)"
    >
      <path
        :class="active === 'chicago' ? 'active hotspot' : 'hotspot'"
        @click="hotspotClicked('chicago')"
        id="chicago"
        data-name="Path 92"
        d="M891.124,426.543a47.364,47.364,0,1,1-47.364-47.362,47.362,47.362,0,0,1,47.364,47.362"
        transform="translate(-796.397 -379.181)"
        stroke="#fff"
        stroke-width="2"
      />
    </g>
    <g data-name="Component 8 – 2" transform="translate(1033.285 622.968)">
      <path
        id="memphis"
        :class="active === 'memphis' ? 'active hotspot' : 'hotspot'"
        @click="hotspotClicked('memphis')"
        class="hotspot"
        data-name="Path 92"
        d="M833,397.481a18.3,18.3,0,1,1-18.3-18.3,18.3,18.3,0,0,1,18.3,18.3"
        transform="translate(-796.397 -379.181)"
        stroke="#fff"
        stroke-width="2"
      />
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    hotspotClicked(hotspot) {
      this.$emit("hotspotClicked", hotspot);
    },
  },
  created() {},
  components: {},
  props: ["active"],
  emits: ["hotspotClicked"],
};
</script>
<style scoped>
svg {
  width: 100%;
  margin: 10% 0;
  height: 100%;
}
.hotspot {
  fill: rgba(255, 255, 255, 0.5);
}
.hotspot:hover {
  cursor: pointer;
  fill: rgba(255, 255, 255, 0.75);
}

.active {
  fill: rgba(255, 255, 255, 1);
}

.active:hover {
  fill: rgba(255, 255, 255, 1);
}
</style>
