<template>
  <div class="heading-wrapper">
    <div :class="['heading-container', alignment]">
      <h2>{{ heading }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: String,
    alignment: {
      type: String,
      default: "center",
      validator: (value) => ["left", "center", "right"].includes(value),
    },
  },
};
</script>

<style scoped>
.heading-wrapper {
  display: flex;
  width: 100%;
  height: fit-content;
}

.heading-container {
  flex: 1;
  display: flex;
}

h2 {
  background-color: black;
  padding: 5px 20px;
  font-size: 20pt;
  color: white;
  margin: 0;
}

.right {
  justify-content: flex-end;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

@media (min-width: 800px) {
  h2 {
    font-size: 40pt;
  }
}
</style>
