<template>
  <div class="copy-section-with-heading">
    <h1>{{ heading }}</h1>
    <p>{{ body }}</p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
  components: {},
  props: ["heading", "body"],
};
</script>

<style scoped>
@media screen {
  .copy-section-with-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    width: 100%;
    margin: 0;
    font-size: 20pt;
    text-align: center;
    border-bottom: 3px solid black;
  }

  p {
    /* padding-left: 5%; */
    font-size: 10pt;
    width: 100%;
    text-align: left;
  }
  @media (min-width: 500px) {
    h1 {
      font-size: 24pt;
    }

    p {
      font-size: 12pt;
    }
  }
  @media (min-width: 800px) {
    h1 {
      width: 30%;
      padding: 30px 30px 30px 0;
      font-size: 35pt;
      border-bottom: unset;
    }

    p {
      width: 60%;
    }
  }
}
</style>
