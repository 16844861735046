<template>
  <Header />
  <router-view></router-view>
</template>

<script>
import Header from "./components/SharedComponentsUI/Header.vue";
export default {
  name: "Cole Coleman",
  components: {
    Header,
  },
};
</script>

<style>
@import url("https://use.typekit.net/elo1gbf.css");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: bebas-neue, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: black;
}

p {
  font-family: calluna, serif;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
