<template>
  <div class="shoutout-wrapper">
    <h1 class="shoutout-text">{{ shoutout.title }}</h1>
    <h1 class="shoutout-number">{{ shoutout.number }}</h1>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
  components: {},
  props: ["shoutout"],
};
</script>
<style scoped>
@media screen {
  h1 {
    margin: 0;
  }
  .shoutout-wrapper {
    display: flex;
    flex-direction: column;
  }

  .shoutout-text {
    font-size: 25pt;
  }

  .shoutout-number {
    font-size: 30pt;
  }
  @media (min-width: 800px) {
    @media (orientation: portrait) {
      .shoutout-text {
        font-size: 30pt;
      }

      .shoutout-number {
        font-size: 45pt;
      }
    }
    @media (orientation: landscape) {
      .shoutout-text {
        font-size: 20pt;
      }
    }
  }
}
</style>
