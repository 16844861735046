<template>
  <div class="block-quote-wrapper">
    <img
      src="../../assets/left-parenthesis.svg"
      class="left-parenthesis"
      alt=""
    />
    <p>{{ quote }}</p>
    <img
      src="../../assets/right-parenthesis.svg"
      class="right-parenthesis"
      alt=""
    />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
  components: {},
  props: ["quote"],
};
</script>
<style scoped>
@media screen {
  .block-quote-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
  }

  .left-parenthesis {
    align-self: flex-start;
    width: 14px;
  }

  .right-parenthesis {
    align-self: flex-end;
    width: 14px;
  }

  p {
    font-weight: 600;
    font-size: 10pt;
    line-height: 14pt;
    margin: 0 15px;
  }
  @media (min-width: 500px) {
    p {
      font-size: 14pt;
      line-height: 18pt;
    }
  }
  @media (min-width: 800px) {
    .block-quote-wrapper {
      width: 70%;
    }

    .left-parenthesis {
      width: unset;
    }

    .right-parenthesis {
      width: unset;
    }

    p {
      font-size: 16pt;
      line-height: 20pt;
    }
  }
}
</style>
